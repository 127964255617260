"use client";

import React from "react";
import NextLink from "next/link";
import { useSelectedLayoutSegments } from "next/navigation";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import Call from "@mui/icons-material/Call";
import Mail from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import Logo from "@/src/app/(client)/_features/components/appbar/Logo";

function Copyright() {
  return (
    <Typography variant="body2" mt={1}>
      {"Copyright © "}
      <Link href="https://brain-mentors.com/">Brain-mentors&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const segments = useSelectedLayoutSegments();
  const isLandingPage = segments.includes("landing");

  return (
    <Box bgcolor="#000" color="#fff">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 8 },
          py: { xs: 8, sm: 10 },
          textAlign: { sm: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              minWidth: { xs: "100%", sm: "60%" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
              <Logo text="Brain Mentors" />
              <Box mt={2}>
                <Typography display="flex" alignItems="center" variant="body2">
                  <Mail sx={{ mr: 1 }} fontSize="small" />
                  hr@brain-mentors.com
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  display="flex"
                  alignItems="center"
                >
                  <Call sx={{ mr: 1 }} fontSize="small" /> 7042994524
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  display="flex"
                  alignItems="center"
                >
                  <Call sx={{ mr: 1 }} fontSize="small" /> 7042434524
                </Typography>

                <Typography variant="subtitle2" mt={3}>
                  Monday – Sunday: 10:00 AM – 07:00 PM
                </Typography>
                <Typography variant="body2">
                  Brain Mentors Pvt Ltd, Second Floor, Anandam Square, Meerut
                  Road Industrial Area, Ghaziabad, Uttar Pradesh-201003
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isLandingPage && (
            <>
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="body2" fontWeight={600}>
                  Company
                </Typography>
                <Link component={NextLink} href="/about-us">
                  About us
                </Link>

                <Link component={NextLink} href="/sitemap.xml">
                  Site-map
                </Link>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="body2" fontWeight={600}>
                  Legal
                </Typography>
                <Link component={NextLink} href="/terms-of-service">
                  Terms
                </Link>
                <Link component={NextLink} href="/privacy-policy">
                  Privacy
                </Link>
              </Box>
            </>
          )}
        </Box>
        {!isLandingPage && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pt: { xs: 4, sm: 2 },
                width: "100%",
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <div>
                <Link color="text.secondary" href="/privacy-policy">
                  Privacy Policy
                </Link>
                <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
                  &nbsp;•&nbsp;
                </Typography>
                <Link color="text.secondary" href="/terms-of-service">
                  Terms of Service
                </Link>
                <Copyright />
              </div>
              <Stack
                direction="row"
                justifyContent="left"
                spacing={1}
                useFlexGap
                flexWrap={"wrap"}
                sx={{
                  color: "text.secondary",
                }}
              >
                <IconButton
                  color="error"
                  href="https://www.youtube.com/@BrainMentorsPvtLtd"
                  aria-label="Youtube"
                  sx={{ alignSelf: "center" }}
                >
                  <YouTubeIcon fontSize="small" />
                </IconButton>
                <IconButton
                  color="error"
                  href="https://in.linkedin.com/company/brain-mentors"
                  aria-label="LinkedIn"
                  sx={{ alignSelf: "center" }}
                >
                  <LinkedInIcon fontSize="small" />
                </IconButton>
                <IconButton
                  color="error"
                  href="https://www.instagram.com/brain.mentors"
                  aria-label="Instagram"
                  sx={{ alignSelf: "center" }}
                >
                  <InstagramIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}
