"use client";

import React from "react";
import { Box, Drawer } from "@mui/material";
import useDrawerStore from "./drawerStore";
import Logo from "./Logo";
import { Page } from "../../models/pageModel";
import { useSelectedLayoutSegments } from "next/navigation";
import MobileViewLinks from "./menu/MobileViewLinks";

export default function SideBarDrawer({ pages }: { pages: Page[] }) {
  const segments = useSelectedLayoutSegments();
  const isLandingPage = segments.includes("landing");
  const { open, toggle } = useDrawerStore();

  if (isLandingPage) return <></>;
  return (
    <div>
      <nav>
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={toggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
        >
          <Box height={"100%"}>
            <Box display={"flex"} p={2} alignItems={"center"}>
              <Logo text="Brain Mentors" />
              <Box flexGrow={1} />
            </Box>
            <Box px={2} sx={{ height: "100%", overflowY: "auto" }}>
              <MobileViewLinks pages={pages} />
            </Box>
          </Box>
        </Drawer>
      </nav>
    </div>
  );
}
