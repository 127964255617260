"use client";
import React, { useState } from "react";
import Link from "next/link";
import { ChevronRight, ChevronUp } from "lucide-react"; // Ensure you have lucide-react installed
import "./verticalMenu.css";
import { Page } from "../../../models/pageModel";
import useDrawerStore from "../drawerStore";

const MobileViewLinks = ({ pages }: { pages: Page[] }) => {
  const [expandedPages, setExpandedPages] = useState<{
    [key: string]: boolean;
  }>({});
  const { open, toggle } = useDrawerStore();

  const toggleExpansion = (pageId: string) => {
    setExpandedPages((prevExpandedPages) => ({
      ...prevExpandedPages,
      [pageId]: !prevExpandedPages[pageId],
    }));
  };

  const renderMenu = (items: Page[], level = 0) => {
    return items.map((item) => {
      const { page_id, title, slug, sub_pages, has_link, menu_visibility } =
        item;

      if (!menu_visibility) {
        return null;
      }

      const isExpanded = expandedPages[page_id] || false;

      return (
        <li key={page_id} style={{ marginLeft: `${level * 10}px` }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => toggleExpansion(page_id)}
            className="menu-item"
          >
            {sub_pages &&
              sub_pages.length > 0 &&
              (isExpanded ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronRight size={20} />
              ))}
            {has_link ? (
              <Link
                onClick={() => {
                  toggle();
                  setExpandedPages({});
                }}
                href={`/${slug}`}
                className="menu-link"
              >
                {title}
              </Link>
            ) : (
              <span className="menu-text">{title}</span>
            )}
          </div>
          <div
            style={{ height: "100%" }}
            className={`sub-menu-container ${isExpanded ? "expanded" : ""}`}
          >
            {sub_pages && sub_pages.length > 0 && (
              <ul className="sub-menu">{renderMenu(sub_pages, level + 1)}</ul>
            )}
          </div>
        </li>
      );
    });
  };

  return (
    <nav>
      <ul>{renderMenu(pages)}</ul>
    </nav>
  );
};

export default MobileViewLinks;
