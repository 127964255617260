"use client";

import React from "react";
import CourseItem from "./CourseMenuItem";
import { Page } from "../../../models/pageModel";

export default function CourseMenu({
  courseMenuItems,
}: {
  courseMenuItems: Page[];
}) {
  
  return (
    <ul className="course-menu">
      {courseMenuItems.map((item) => (
        <CourseItem key={item.page_id} {...item} depthLevel={1} />
      ))}
    </ul>
  );
}
