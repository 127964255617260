"use client";

import React from "react";
import Menu from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import useDrawerStore from "./drawerStore";

export default function OpenDrawerButton() {
  const { toggle } = useDrawerStore();
  return (
    <div>
      <IconButton
        onClick={toggle}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Menu color="primary" />
      </IconButton>
    </div>
  );
}
