import { create } from "zustand";

export type DrawerState = {
  open: boolean;
};

export type Actions = {
  toggle: () => void;
};
const useDrawerStore = create<DrawerState & Actions>((set) => ({
  open: false,
  toggle: () => {
    set((state) => ({ open: !state.open }));
  },
}));

export default useDrawerStore;
