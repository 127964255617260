"use client";

import React from "react";
import Link from "next/link";
import "./menustyle.css";

export default function CourseItem({
  title,
  slug,
  has_link,
  sub_pages,
  depthLevel,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMouseEnter = () => {
    if (sub_pages) {
      setIsOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (sub_pages) {
      setIsOpen(false);
    }
  };

  const rendersub_pages = () => {
    if (sub_pages && isOpen) {
      return (
        <ul>
          {sub_pages.map((item) => {
            if (!item.menu_visibility) {
              return null;
            }
            return (
              <CourseItem
                key={item.title}
                {...item}
                depthLevel={depthLevel + 1}
              />
            );
          })}
        </ul>
      );
    }
    return null;
  };

  return (
    <li
      style={{ fontSize: "14px" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {has_link ? <Link href={`/${slug}`}>{title}</Link> : `${title}`}
      {rendersub_pages()}
    </li>
  );
}
