"use client";

import React from "react";
import { Avatar, Typography, Box } from "@mui/material";
import Link from "next/link";
import { useSelectedLayoutSegments } from "next/navigation";
import Image from "next/image";

export default function Logo({ text }: { text: string }) {
  const segments = useSelectedLayoutSegments();
  const isLandingPage = segments.includes("landing");
  if (isLandingPage) {
    return (
      <Box
        color="#E50914"
        gap={1}
        sx={{
          textDecoration: "none",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
            opacity: 0.6,
          },
        }}
      >
        <Image
          priority={false}
          height={40}
          width={40}
          src="/images/logo.webp"
          alt="Brain mentors pvt. ltd."
        />
        <Typography variant="h6" fontWeight={700} noWrap>
          {text}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      color="#E50914"
      component={Link}
      gap={1}
      href={"/"}
      sx={{
        textDecoration: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "&:hover": {
          cursor: "pointer",
          opacity: 0.6,
        },
      }}
    >
      <Avatar src="/images/logo.webp" alt="Brain mentors pvt. ltd." />
      <Typography variant="h6" fontWeight={700} noWrap>
        {text}
      </Typography>
    </Box>
  );
}
