"use client";

import React from "react";
import { Box, Toolbar, Container } from "@mui/material";
import CourseMenu from "./menu/CourseMenu";
import Logo from "./Logo";
import OpenDrawerButton from "./OpenDrawerButton";
import { Page } from "../../models/pageModel";
import { useSelectedLayoutSegments } from "next/navigation";
import EnquiryButton from "../EnquiryButton";

export default function ToolbarComponent({ pages }: { pages: Page[] }) {
  const segments = useSelectedLayoutSegments();
  const isLandingPage = segments.includes("landing");
  return (
    <Toolbar
      variant="regular"
      sx={(theme) => ({
        padding: 0,
        margin: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexShrink: 0,
        bgcolor:
          theme.palette.mode === "light"
            ? "rgba(255, 255, 255, 0.4)"
            : "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(24px)",
        maxHeight: 40,
        boxShadow:
          theme.palette.mode === "light"
            ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
            : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Logo text="Brain Mentors" />
        <Box flexGrow={1} />
        {isLandingPage ? (
          <Box
            sx={{
              display: { md: "flex" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <EnquiryButton
              size="small"
              action="ThankYouMessage"
              text="Book a demo"
            />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: { xs: "none", md: "flex", sm: "none" },
              }}
            >
              <CourseMenu courseMenuItems={pages} />
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <EnquiryButton
                size="small"
                action="ThankYouMessage"
                text="Book a demo"
              />
            </Box>
            <OpenDrawerButton />
          </>
        )}
      </Box>
    </Toolbar>
  );
}
